export const host = "https://wss-api.paknext.com" //"https://api-wss.herokuapp.com"  //" "http://localhost:4000";
export const appVersion = '0.1.1';
export const storageKeys = {
    appTokken: 'apTkn'
}


export const dataLimit = {
    perPage: 25,
}

export const contentViews = {
    dashboard:  'dashboard',
    profile:    'profile',
    admins:     'admins',
    tsp:        'tsp',
    omc:        'omc',
    packages:   'packages',
    stations:   'weigh-stations',
    requests:   'requests',
    directors:  'directors',
    newResource:'new-resource',
    activities: 'activities',
}

export const endPoints = {
    auth: {
        login:          '/app/auth/login',
        genAuthCode:    '/app/auth/generate-code',
        resetPassword:  '/app/auth/forgot',
        updateProfile:  '/app/auth/update-profile',
        updateImage:    '/app/auth/update-image',
        myProfile:      '/app/auth/my-profile',
        listDevices:    '/app/auth/list-devices',
        logout:         '/app/auth/logout',
    },

    users: {
        fetchUserByRole:'/app/users/list-by-role',
        addUser:        '/app/users/add',
        suspension:     '/app/users/suspension',
        delete:         '/app/users/delete',
        update:         '/app/users/update',
    },

    package: {
        listAll:        '/app/package/list-all',
        filter:         '/app/package/filter',
        newPackage:     '/app/package/add-new',
        delPackage:     '/app/package/delete',
        replaceManager: '/app/package/replace-manager',
    },

    station: {
        listAll:        '/app/station/list-all',
        filter:         '/app/station/filter',
        update:         '/app/station/update',
        updateOperator: '/app/station/update-operator',
        deleteStation:  '/app/station/delete',
    },
    
    request: {
        listAll:        '/app/request/list-all',
        single:         '/app/request/single',
        assignTofficer: '/app/request/assign-officer',
        generateReq:    '/app/request/generate',
        resolveReq:     '/app/request/resolve',
        generatePdf:    '/app/request/gen-pdf',
    },

    company: {
        listAll:        '/app/company/list-all',
        addNew:         '/app/company/add-new',
        delete:         '/app/company/delete',
        addManager:     '/app/company/add-manager',
        deleteManager:  '/app/company/del-manager',
        addPlayer:      '/app/company/add-player',
        deletePlayer:   '/app/company/del-player',
    },
    chat: {
        listMsgs:       '/app/chat/list-messages',
        listAttachments:'/app/chat/list-attachments',
        sendMsg:        '/app/chat/send-message',
        sendAttachment: '/app/chat/send-attachment',
    },

    activity: {
        listAll:       '/app/activity/list-all',
    },
    
    report: {
        countAll:      '/app/report/count-all',
        requestCount:  '/app/report/request-count',
    }
}