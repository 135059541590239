import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Select, MenuItem, CircularProgress } from '@material-ui/core';

import { setSnackMsg, updateRequest, fetchRequests, emptyRequests } from '../../../../redux/requests/request-actions';
import { fetchStations, emptyStationList } from '../../../../redux/station/station-actions';

import Utility from '../../../../lib/utility';
import { PrimaryInput } from '../../../../components/inputs';
import { CustomDialog } from '../../../../components/dialog';
import { CustomSnackbar } from '../../../../components/messages';
import { PrimaryButton } from '../../../../components/buttons';
import { endPoints } from '../../../../lib/values';

class NewRequest extends React.Component {

    state = {
        inputs: {},
    }

    componentDidMount(){
        this.filterStationForOMC()
    }

    componentWillUnmount(){
        this.props.emptyStationList();
    }

    updateRequest = () => {
        const { inputs } = this.state;
        
        if(!inputs.sub_problem || inputs.sub_problem === ' ') 
            return this.props.setSnackMsg("Sub problem is missing");
        console.log(inputs);
        this.props.updateRequest(endPoints.request.generateReq, Utility.httpRequest('POST', inputs), (err, jsonRes) => {
            if(err === null){
                this.setState({ inputs: {} })
                this.props.emptyRequests()
                this.fetchRequests()
                this.props.onClose()
            }
        })
    }

    filterStationForOMC = () => {
        const { authReducer } = this.props;
        const url = `${endPoints.station.filter}?operator=${authReducer.user._id}`;
        this.props.filterStationForOMC(url, Utility.httpRequest('GET'), (err, jsonRes) => {
            // do something....
        })
    }

    fetchRequests = () => {
        const url = `${endPoints.request.listAll}`;
        this.props.fetchRequests(url, Utility.httpRequest('GET'), (err, jsonData) => {
            // do something here....
        })
    }

    renderSelectedStation  = () => {
        const { inputs } = this.state;
        let selectedStation = null;
        if(!inputs.station_id) return;
        
        this.props.stationReducer.stations.find(station => {
            if(station._id === inputs.station_id){
                selectedStation = station;
                return true;
            }
            return undefined;
        })
        return selectedStation;
    }

    renderHardwareSubProb = () => {
        const { inputs } = this.state;
        return (
            <FormControl fullWidth className="animate__animated animate__shakeX">
                <Select
                    fullWidth
                    variant     = "outlined"
                    style       = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                    value       = { inputs.sub_problem || " " }
                    onChange    = { e => this.setState({ inputs: { ...inputs, sub_problem: e.target.value } }) }>
                    <MenuItem value={" "}>
                        <em>Choose Hardware Problem</em>
                    </MenuItem>
                    <MenuItem value = {"Weight Plate"}>Weight Plate</MenuItem> 
                    <MenuItem value = {"Weighing Equipment"}>Weighing Equipment</MenuItem> 
                    <MenuItem value = {"Weight Indicator"}>Weight Indicator</MenuItem> 
                    <MenuItem value = {"Large Scale Display"}>Large Scale Display</MenuItem> 
                    <MenuItem value = {"Main Computer"}>Main Computer</MenuItem> 
                    <MenuItem value = {"Keyboard"}>Keyboard</MenuItem> 
                    <MenuItem value = {"Mouse"}>Mouse</MenuItem> 
                    <MenuItem value = {"Monitor"}>Monitor</MenuItem> 
                    <MenuItem value = {"UPS"}>UPS</MenuItem> 
                    <MenuItem value = {"Printer"}>Printer</MenuItem> 
                </Select>
            </FormControl>
        )
    }
    
    renderSoftwareSubProb = () => {
        const { inputs, } = this.state;
        return (
            <FormControl fullWidth className="animate__animated animate__shakeX">
                <Select
                    variant = "outlined"
                    fullWidth
                    style = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                    value  = { inputs.sub_problem || " " }
                    onChange  ={ e => this.setState({ inputs: { ...inputs, sub_problem: e.target.value } }) }>
                    <MenuItem value={" "}>
                        <em>Choose Software Problem</em>
                    </MenuItem>
                    <MenuItem value = {"Operator Display"}>Operator Display</MenuItem> 
                    <MenuItem value = {"Operator Window"}>Operator Window</MenuItem> 
                    <MenuItem value = {"Data Entry"}>Data Entry</MenuItem> 
                    <MenuItem value = {"Vehicle Class"}>Vehicle Class</MenuItem> 
                    <MenuItem value = {"Vehicle Axle Class"}>Vehicle Axle Class</MenuItem> 
                    <MenuItem value = {"Vehicle Detection"}>Vehicle Detection</MenuItem> 
                    <MenuItem value = {"Vehicle Gross Weight"}>Vehicle Gross Weight</MenuItem> 
                    <MenuItem value = {"Timing of System"}>Timing of System</MenuItem> 
                    <MenuItem value = {"Print"}>Print</MenuItem> 
                    <MenuItem value = {"Reports"}>Reports</MenuItem>
                </Select>
            </FormControl>
        )
    }

    renderElectricalProb = () => {
        const { inputs, } = this.state;
        return (
            <FormControl fullWidth className="animate__animated animate__shakeX">
                <Select
                    variant = "outlined"
                    fullWidth
                    style = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                    value  = { inputs.sub_problem || " " }
                    onChange  ={ e => this.setState({ inputs: { ...inputs, sub_problem: e.target.value } }) }>
                    <MenuItem value={" "}>
                        <em>Choose Electrical Problem</em>
                    </MenuItem>
                    <MenuItem value = {"Generator"}>Generator</MenuItem> 
                    <MenuItem value = {"ATS"}>ATS</MenuItem> 
                    <MenuItem value = {"UPS"}>UPS</MenuItem> 
                    <MenuItem value = {"SPD"}>SPD</MenuItem> 
                    <MenuItem value = {"Voltmeter"}>Voltmeter</MenuItem> 
                </Select>
            </FormControl>
        )
    }

    renderNewRequest = () => {
        const { inputs, } = this.state;
        const { stationReducer } = this.props;
        return (
            stationReducer.fetchLoader 
            ? 
            <div style={{ display: 'flex', justifyContent: 'center', padding: 16 }}><CircularProgress color="secondary" /></div>
            : 
            <> 
                <PrimaryInput
                    inputValue          =  { inputs.title || "" }
                    inputfullWidth      = { true }
                    inputLabel          = "Title"
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = "Monitor screen turned black"
                    onChangeInput       = {(e) => this.setState({ inputs: { ...inputs, title: e.target.value }})}
                />

                <FormControl fullWidth>
                    <Select
                        variant = "outlined"
                        fullWidth
                        style = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value  = { inputs.station_id || " " }
                        onChange  ={ e => this.setState({ inputs: { ...inputs, station_id: e.target.value } }) }>
                        <MenuItem value={" "}>
                            <em>Choose Station</em>
                        </MenuItem>
                        { stationReducer.stations.map((station) => 
                            <MenuItem key={station._id} value = {station._id}>{station.name}</MenuItem> 
                        )}
                    </Select>
                </FormControl>
                { this.renderSelectedStation() &&
                    <div style={{ display: 'flex', flex: 1 }} className="animate__animated animate__shakeX">
                        <PrimaryInput
                            inputValue          = { this.renderSelectedStation().bound }
                            inputfullWidth      = { true }
                            inputLabel          = "Bound"
                            formControlClasses  = { "light-input-container" }
                            formControlStyle    = {{ marginRight: 16 }}
                            inputDisabled       = { true }
                        />
                        <PrimaryInput
                            inputValue          = { this.renderSelectedStation().lane }
                            inputfullWidth      = { true }
                            inputLabel          = "Lane"
                            formControlClasses  = { "light-input-container" }
                            inputDisabled       = { true }
                        />
                    </div>
                }
                <FormControl fullWidth>
                    <Select
                        variant = "outlined"
                        fullWidth
                        style = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value  = { inputs.request_type || " " }
                        onChange  ={ e => this.setState({ inputs: { ...inputs, request_type: e.target.value } }) }>
                        <MenuItem value={" "}>
                            <em>Request Type</em>
                        </MenuItem>
                        <MenuItem value = {1}>Routine Maintenance</MenuItem> 
                        <MenuItem value = {2}>Incidental Maintenance</MenuItem> 
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <Select
                        variant = "outlined"
                        fullWidth
                        style = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value  = { inputs.support_required || " " }
                        onChange  ={ e => this.setState({ inputs: { ...inputs, support_required: e.target.value } }) }>
                        <MenuItem value={" "}>
                            <em>Request Type</em>
                        </MenuItem>
                        <MenuItem value = {1}>Normal</MenuItem> 
                        <MenuItem value = {2}>Urgent</MenuItem> 
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <Select
                        variant = "outlined"
                        fullWidth
                        style = {{ backgroundColor: '#f2f2f2', marginBottom: 24 }}
                        value  = { inputs.problem_type || " " }
                        onChange  ={ e => this.setState({ inputs: { ...inputs, problem_type: e.target.value, sub_problem: " " } }, () => console.log(this.state)) }>
                        <MenuItem value={" "}>
                            <em>Problem Related To</em>
                        </MenuItem>
                        <MenuItem value = {1}>Hardware</MenuItem> 
                        <MenuItem value = {2}>Software</MenuItem> 
                        <MenuItem value = {3}>Electrical</MenuItem> 
                    </Select>
                </FormControl>

                { this.state.inputs.problem_type === 1 && this.renderHardwareSubProb() }
                { this.state.inputs.problem_type === 2 && this.renderSoftwareSubProb() }
                { this.state.inputs.problem_type === 3 && this.renderElectricalProb() }

                <PrimaryInput
                    inputValue          = { inputs.problem || "" }
                    inputfullWidth      = { true }
                    inputMultiline      = { true }
                    inputRows           = { 4}
                    inputLabel          = { "Problem Description"}
                    formControlClasses  = { "light-input-container" }
                    inputPlaceholder    = { "Detail about actual problem"}
                    onChangeInput       = { e => this.setState({ inputs: { ...inputs, problem: e.target.value }})}
                />
            </>
        )
    }

    renderAction = () => {
        const { requestReducer } = this.props;
        return (
            <>
            <div className="dialog-action-container">
                <CustomSnackbar
                    show    = { Boolean(requestReducer.snackMsg) }
                    title   = { requestReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />
            </div>
            <PrimaryButton
                buttonText      = "Generate Request"
                inProgress      = { requestReducer.updateLoader }
                buttonDisabled  = { requestReducer.updateLoader }
                onClick         = { this.updateRequest }
            />
            </>
        )
    }

    render(){
        const { showDialog } = this.props;
        return (
            <CustomDialog 
                open    = { showDialog }
                title   = { "New Request" }
                content = { this.renderNewRequest()  }
                action  = { this.renderAction() }
                onClose = { this.props.onClose }
            />   
        )
    }
}

function mapStateToProp(state){
    return {
        stationReducer: state.stationReducer,
        requestReducer: state.requestReducer,
        authReducer:    state.authReducer,
    }
}


function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:        (msg)           => dispatch(setSnackMsg(msg)),
        emptyRequests:      ()              => dispatch(emptyRequests()),
        emptyStationList:   ()              => dispatch(emptyStationList()),
        updateRequest:      (url, body, cb) => dispatch(updateRequest(url, body, cb)),
        filterStationForOMC:(url, body, cb) => dispatch(fetchStations(url, body, cb)),
        fetchRequests:      (url, body, cb) => dispatch(fetchRequests(url, body, cb)),
        
        
    }
}


export default connect(mapStateToProp, mapDispatchToProps)(NewRequest);